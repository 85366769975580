<template>
  <v-app>
    <div>
      <v-data-table
        :headers="headers"
        :items="this.AllBookingsInfo"
        :search="search"
        class="elevation-1"
        :item-class="itemRowBackground"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Rezervari</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- Dialog -->
            <v-dialog v-model="dialog" max-width="500px">
              <v-card class="ma-0 pa-0">
                <v-card-title>
                  <span class="headline">Salveaza raport pentru: <strong>{{editedItem.user.data.name}}</strong></span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                            ref="menustart"
                            v-model="menustart"
                            :close-on-content-click="false"
                            :return-value.sync="datestart"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="datestart"
                                label="De la data"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="datestart"
                              no-title
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menustart = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menustart.save(datestart)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="enddate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="enddate"
                                label="Pana la data"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="enddate"
                              no-title
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(enddate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDownload">
                    Anuleaza
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="startDownload">
                    Salveaza
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Cauta"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="cancelBooking(item)">
            mdi-close
          </v-icon>
          <v-icon small class="mr-2" @click="downloadBooking(item)">
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<style lang="scss">
.v-data-footer__select {
  opacity: 0 !important;
}
.row-cancelled-booking {
  opacity: 0.3;
  background: rgba(0,0,0,0.1);
}

.row-cancelled-booking .text-start:last-child button{
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_BOOKINGS } from "@/core/services/store/booking.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {CANCEL_BOOKING, DOWNLOAD_REPORT} from "../../../core/services/store/booking.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    dialog: false,
    dialogAdmin: false,
    search: "",
    headers: [
      { text: "ID", value: "id" },
      {
        text: "Camera",
        align: "left",
        sortable: true,
        value: "room.data.name"
      },
      { text: "Utilizator", value: "user.data.name" },
      { text: "Data", value: "date_start" },
      { text: "Status", value: "status" },
      { text: "Actiuni", value: "actions", sortable: false }
    ],
    bookings: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      user: {
        data: {
          name: ""
        }
      }
    },
    defaultItem: {
      id: "",
      user: {
        data: {
          name: ""
        }
      }
    }
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vezi toate rezervarile" }]);
    this.$store.dispatch(GET_ALL_BOOKINGS);
  },
  computed: {
    ...mapGetters(["AllBookingsInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Editeaza Rezervare";
    }
  },

  watch: {
    dialog(val) {
      val || this.closeDownload();
    },
    dialogAdmin(val) {
      val || this.closeDelete();
    }
  },

  created() {
    // this.initialize();
  },

  methods: {
    initialize() {
      this.bookings = this.AllBookingsInfo.data || this.$store.getters.AllBookingsInfo.data;
    },
    cancelBooking(item) {
      this.$store.dispatch(CANCEL_BOOKING, item);
    },
    downloadBooking(item) {
      this.editedIndex = this.bookings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log("editedItem: ", this.editedItem);
      this.dialog = true;
    },
    startDownload() {
      if (this.datestart && this.enddate) {
        this.$store.dispatch(DOWNLOAD_REPORT, [{ id: this.editedItem.user.data.id, date_from: this.datestart, date_to: this.enddate }]).then(()=>{
          Swal.fire({
            title: "",
            text: "Succes!",
            icon: "success",
            confirmButtonClass: "btn btn-success"
          });
        })
        this.closeDownload();
      } else {
        Swal.fire({
          title: "",
          text: "A aparut o eroare: datele nu au fost selectate.",
          icon: "error",
          confirmButtonClass: "btn btn-error"
        });
      }
    },
    closeDownload() {
      this.dialog = false;
    },
    itemRowBackground: function (item) {
      return item.status === 'Anulata' ? 'row-cancelled-booking' : ''
    }
  }
};
</script>
